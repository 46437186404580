import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

const EMAIL = "1264188bcltd@gmail.com";
const PHONE = "778-885-3556";

function EntryNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="primary">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
            //   href="https://demos.creative-tim.com/now-ui-kit-react/#/index?ref=nukr-index-navbar"
            href="/entry"
              // target="_blank"
              id="navbar-brand"
            >

              MT Coring
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              {/*<NavItem>
                <NavLink
                  href="/about"
                  onClick={(e) => {
                    // e.preventDefault();
                  }}
                >
                  <p>About</p>
                </NavLink>
              </NavItem>*/}
              <NavItem>
                <NavLink
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("contact-us-section")
                      .scrollIntoView();
                  }}
                >
                  <p>Contact us</p>
                </NavLink>
              </NavItem>

              {/*branding */}
              {
                collapseOpen && (
                  <>
                  <NavItem>
                    <NavLink
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      MT Coring © {new Date().getFullYear()}
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      href={`mailto:${EMAIL}`}
                      target="_blank"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <i className="now-ui-icons ui-1_email-85 mr-1"></i>
                      Email: {EMAIL}
                    </NavLink>
                  </NavItem>
              
                  <NavItem>
                    <NavLink
                      href={`tel:${PHONE}`}
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                    <i className="now-ui-icons ui-2_chat-round mr-1"></i>
                      Phone: {PHONE}
                    </NavLink>
                  </NavItem>
                </>)
              }
            
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default EntryNavbar;
