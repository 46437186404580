import React from 'react';
import {
    Container,
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselIndicators
} from "reactstrap";

function CarouImages() {
    const videoPreload = 0.1;
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const [isPlaying, setPlaying] = React.useState(false);

    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        if (isPlaying) {
          isPlaying.target.pause();
        }
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        if (isPlaying) {
          isPlaying.target.pause();
        }
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = (newIndex) => {
        if (isPlaying) {
          isPlaying.target.pause();
        }
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const videoOnplay = (video) => {
      setPlaying(video);
    }

    const videoOnstop = (video) => {
      setPlaying(null);
    }

    const items = [
        {
          src: require("assets/img/mikescoring/abg.jpg"),
          altText: "Nature, United States",
          caption: "Nature, United States"
        },
        {
          src: require("assets/img/mikescoring/bbg.jpg"),
          altText: "Somewhere Beyond, United States",
          caption: "Somewhere Beyond, United States"
        },
        {
          src: require("assets/img/mikescoring/dbg.jpg"),
          altText: "Yellowstone National Park, United States",
          caption: "Yellowstone National Park, United States"
        }
    ];

    const videos = [
      {
        src: require("assets/img/mikescoring/drill_1.webm"),
        altText: "Placeholder",
        caption: "Placeholder"
      },
      {
        src: require("assets/img/mikescoring/drill_2.webm"),
        altText: "Placeholder2",
        caption: "Placeholder2"
      },
      {
        src: require("assets/img/mikescoring/drill_3.webm"),
        altText: "Placeholder3",
        caption: "Placeholder3"
      }
    ];

    return (
        <>
        <div className="section" id="carousel">
          <Container>
            {/*<div className="title">
               <h4>Carousel</h4> 
            </div>*/}
            <Row className="justify-content-center">
              <Col lg="8" md="12">
               {/*style={{maxHeight: 500, maxWidth: 500}}*/}
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                  interval={null}
                  // style={{height: 300, width: 300}}
                >
                  <CarouselIndicators
                    items={videos}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {videos.map((vid) => {
                    return (
                      <CarouselItem 
                        onExiting={onExiting}
                        onExited={onExited}
                        key={vid.src}
                        // style={{maxWidth: 300, maxHeight: 300}}
                      >
                        <video style={{height: '600px', width: '100%'}} preload={"metadata"} src={vid.src + `#t=${videoPreload}`} alt={vid.altText} controls muted onPlay={videoOnplay}>
                          <source src={vid.src} />
                        </video>
                      </CarouselItem>
                    )
                  })}
                  {/*{items.map((item) => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div>
                      </CarouselItem>
                    );
                  })}*/}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    )
}

export default CarouImages;

// import React from "react";

// // reactstrap components
// import {
//   Container,
//   Row,
//   Col,
//   Carousel,
//   CarouselItem,
//   CarouselIndicators
// } from "reactstrap";

// // core components

// const items = [
//   {
//     src: require("assets/img/bg1.jpg"),
//     altText: "Nature, United States",
//     caption: "Nature, United States"
//   },
//   {
//     src: require("assets/img/bg3.jpg"),
//     altText: "Somewhere Beyond, United States",
//     caption: "Somewhere Beyond, United States"
//   },
//   {
//     src: require("assets/img/bg4.jpg"),
//     altText: "Yellowstone National Park, United States",
//     caption: "Yellowstone National Park, United States"
//   }
// ];

// function CarouselSection() {
//   const [activeIndex, setActiveIndex] = React.useState(0);
//   const [animating, setAnimating] = React.useState(false);
//   const onExiting = () => {
//     setAnimating(true);
//   };
//   const onExited = () => {
//     setAnimating(false);
//   };
//   const next = () => {
//     if (animating) return;
//     const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
//     setActiveIndex(nextIndex);
//   };
//   const previous = () => {
//     if (animating) return;
//     const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
//     setActiveIndex(nextIndex);
//   };
//   const goToIndex = (newIndex) => {
//     if (animating) return;
//     setActiveIndex(newIndex);
//   };
//   return (
//     <>
//       <div className="section" id="carousel">
//         <Container>
//           <div className="title">
//             <h4>Carousel</h4>
//           </div>
//           <Row className="justify-content-center">
//             <Col lg="8" md="12">
//               <Carousel
//                 activeIndex={activeIndex}
//                 next={next}
//                 previous={previous}
//               >
//                 <CarouselIndicators
//                   items={items}
//                   activeIndex={activeIndex}
//                   onClickHandler={goToIndex}
//                 />
//                 {items.map((item) => {
//                   return (
//                     <CarouselItem
//                       onExiting={onExiting}
//                       onExited={onExited}
//                       key={item.src}
//                     >
//                       <img src={item.src} alt={item.altText} />
//                       <div className="carousel-caption d-none d-md-block">
//                         <h5>{item.caption}</h5>
//                       </div>
//                     </CarouselItem>
//                   );
//                 })}
//                 <a
//                   className="carousel-control-prev"
//                   data-slide="prev"
//                   href="#pablo"
//                   onClick={(e) => {
//                     e.preventDefault();
//                     previous();
//                   }}
//                   role="button"
//                 >
//                   <i className="now-ui-icons arrows-1_minimal-left"></i>
//                 </a>
//                 <a
//                   className="carousel-control-next"
//                   data-slide="next"
//                   href="#pablo"
//                   onClick={(e) => {
//                     e.preventDefault();
//                     next();
//                   }}
//                   role="button"
//                 >
//                   <i className="now-ui-icons arrows-1_minimal-right"></i>
//                 </a>
//               </Carousel>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </>
//   );
// }

// export default CarouselSection;
