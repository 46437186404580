import React from 'react';

import { 
  Card, 
  Container, 
  Input, 
  Form, 
  FormGroup, 
  Button, 
  FormFeedback, 
  FormText, 
  Col,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal
} from 'reactstrap';

import Reaptcha from 'reaptcha';
import sendMail from '../../fetch/sendMail.js';
// import { useForm, form, input } from "react-hook-form";

function ContactModal(props) {
  return (
    <Modal toggle={() => props.setModalLive(false)} isOpen={props.modalLive}>
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLiveLabel">
          {props.template.title ? props.template.title : "Modal title"}
        </h5>
        <button
          aria-label="Close"
          className="close"
          type="button"
          onClick={() => props.setModalLive(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <div className="modal-body">
        {/*<p>Woohoo, you're reading this text in a modal!</p>*/}
        <p>{props.template.body ? props.template.body :  "Woohoo, you're reading this text in a modal!"}</p>
      </div>

      <div className="modal-footer">
        <Button
          style={{'visibility': 'hidden'}}
          color="secondary"
          type="button"
          onClick={() => props.setModalLive(false)}
        >
          Close
        </Button>
        <Button
          color="primary"
          type="button"
          onClick={() => props.setModalLive(false)}
        >
          Close
        </Button>
      </div>
    </Modal>
  )
}


function ContactForm() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [secondFocus, setSecondFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [verified, setVerified] = React.useState(false);
  const [modalLive, setModalLive] = React.useState(false);
  const [template, setTemplate] = React.useState({})
  const [errors, setErrors] = React.useState({name: false, email: false, phonenumber: false});
  // errors sstate for input validations 
  /*
    { errors.email = true } means email is invalid
    { errors.email is null or false means }
  */
  
  var submit = function(evt) {
    console.log('submit is being called: ', evt);
    const name = document.getElementById('data-name');
    const email = document.getElementById('data-email');
    const phonenumber = document.getElementById('data-phone');
    const message = document.getElementById('data-message');
    // console.log('name email phonenumber:');
    // console.log(name);
    const data = {
      "name": name.value, 
      "email": email.value, 
      "phonenumber": phonenumber.value, 
      "message": message.value
    };
    
    // console.log("sending mail: ");

    const modalTemplate = {
      title: "title",
      body: "body"
    }

    sendMail(data)
      .then((success) => {  
        // console.log("results: ", success)
        modalTemplate.title = "Success";
        modalTemplate.body = "The message was sent successfully! We'll be in touch soon";
        setTemplate(modalTemplate);
        setModalLive(true);
      })
      .catch((err) => {
        // console.log('results error: ', err)
        modalTemplate.title = "Error";
        modalTemplate.body = `${err}`;
        setTemplate(modalTemplate);        
        setModalLive(true);
      });
  }

  const validatePhone = function() {

  }



  const form = function() {
  return (
    <Form /*onSubmit={(e) => {
        e.preventDefault();
        // handleSubmit(this.submit(e));
        this.submit(e);
      }}*/
      >
      <ContactModal 
        modalLive={modalLive}
        setModalLive={setModalLive}
        template={template}
      />
      <div className="section section-contact-us text-center" id="contact-us-section">
        <Container>
          <h2 className="title">Would you like a free quote?</h2>
          <p className="description">Your project is very important to us.</p>
          <Row>
            <Col className="text-center ml-auto mr-auto" lg="6" md="8">
              <InputGroup
                className={
                  "input-lg" + (firstFocus ? " input-group-focus" : "")
                }
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="now-ui-icons users_circle-08"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input invalid={errors.name}
                  id="data-name"
                  placeholder="First Name..."
                  type="text"
                  onFocus={() => setFirstFocus(true)}
                  onBlur={(e) => { setFirstFocus(false); validateName(e.target.value); }}
                ></Input>
                {errors.name === true && 
                  <FormFeedback>Please enter your name</FormFeedback>
                }
              </InputGroup>

              <InputGroup
                className={
                  "input-lg" + (secondFocus ? " input-group-focus" : "")
                }
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="now-ui-icons ui-2_chat-round"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  id="data-phone"
                  placeholder="Phone number..."
                  type="tel"
                  onFocus={() => setSecondFocus(true)}
                  onBlur={() => setSecondFocus(false)}
                ></Input>
              </InputGroup>

              <InputGroup
                className={
                  "input-lg" + (lastFocus ? " input-group-focus" : "")
                }
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="now-ui-icons ui-1_email-85"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input invalid={errors.email}
                  id="data-email"
                  placeholder="Email..."
                  type="text"
                  onFocus={(e) => setLastFocus(true)}
                  onBlur={(e) => { setLastFocus(false); validateEmail(e.target.value); }}
                  // onChange={(e) => validateEmail(e.target.value)}
                ></Input>
                {errors.email === true && 
                  <FormFeedback>Please enter a valid email address</FormFeedback>
                }
              </InputGroup>
              <div className="textarea-container">
                <Input
                  id="data-message"
                  cols="80"
                  name="name"
                  placeholder="Type a message..."
                  rows="4"
                  type="textarea"
                ></Input>
              </div>
              <div className="send-button">
                
                <Button
                  block
                  className="btn-round"
                  color="info"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault(); 
                  //   // console.log('onClick: ', e);
                  //   handleSubmit(submit(e));
                    submit(e);
                  }}
                  // type="submit"
                  size="lg"
                  disabled={!verified && !passValidations()}
                  // disabled={checkDisabled()}
                >
                  Send Message
                </Button>
                <Reaptcha 
                  sitekey={'6LcC6nYkAAAAANa_H8-VIHh7voF3qLtWzrqPSRS1'}
                  onVerify={verify}
                />   
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      </Form>
  )
}

const staticForm = function() {
   return (
    <Form /*onSubmit={(e) => {
        e.preventDefault();
        // handleSubmit(this.submit(e));
        this.submit(e);
      }}*/
      >
      <ContactModal 
        modalLive={modalLive}
        setModalLive={setModalLive}
        template={template}
      />
      <div className="section section-contact-us text-center" id="contact-us-section">
        <Container>
          <h2 className="title">Would you like a free quote?</h2>
          <p className="description">Your project is very important to us.</p>
          <Row>
            <Col className="text-center ml-auto mr-auto" lg="6" md="8">
              <InputGroup className="input-lg"
                // className={
                //   "input-lg" + (firstFocus ? " input-group-focus" : "")
                // }
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="now-ui-icons users_circle-08"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input style={{backgroundColor: 'white'}}
                  readOnly
                  id="data-name"
                  placeholder="First Name..."
                  type="text"
                  value="Michael Thomas - Owner Operator"
                  disabled={true}
                ></Input>
              </InputGroup>

              <InputGroup
                className={
                  "input-lg" + (secondFocus ? " input-group-focus" : "")
                }
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="now-ui-icons ui-2_chat-round"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input style={{backgroundColor: 'white'}}
                  readOnly
                  id="data-phone"
                  placeholder="Phone number..."
                  type="tel"
                  value={"778-885-3556"}
                  onFocus={() => setSecondFocus(true)}
                  onBlur={() => setSecondFocus(false)}
                  onClick={(e) => {
                    window.open("tel:778-885-3556", '_blank');
                  }}
                ></Input>
              </InputGroup>

              <InputGroup
                className={
                  "input-lg" + (lastFocus ? " input-group-focus" : "")
                }
              >
                <InputGroupAddon  addonType="prepend">
                  <InputGroupText>
                    <i className="now-ui-icons ui-1_email-85"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input style={{backgroundColor: "white"}}
                  readOnly
                  id="data-email"
                  placeholder="Email..."
                  type="text"
                  value={"1264188BCLTD@GMAIL.COM"}
                  onFocus={() => setLastFocus(true)}
                  onBlur={() => setLastFocus(false)}
                  onClick={(e) => {
                    window.open("mailto:1264188BCLTD@GMAIL.COM?subject=MTCoring inquiry", '_blank');
                  }}
                >
                </Input>

              </InputGroup>
              <div className="send-button">
                <Button
                  block
                  className="btn-round"
                  color="info"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault(); 
                    submit(e);
                  }}
                  size="lg"
                  disabled={true}
                >
                  Give us a call or an email
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      </Form>
      )
}  

const validateEmail = function(email) {
    const matched = email.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);
    const currentErrors = errors;
    const dataEmail = document.getElementById('data-email');

    if (!matched) {
      // we append disabled to #data-email input
      dataEmail.setAttribute('invalid', true);
      currentErrors.email = true;
    } else {
      dataEmail.removeAttribute('invalid');
      currentErrors.email = false;
    }

    setErrors(currentErrors);

    console.log('currentErrors: ', currentErrors, matched);
  }

  const all = function(string, thing) {
    for (let i = 0; i < string.length; i++) {
      if (string[i] != thing) return false;
    }

    return true;
  }

  const validateName = function(name) {
    const currentErrors = errors;
    const dataName = document.getElementById('data-name');

    const allSpaces = all(name, " ");

    if (name == "" || !name || name == null || allSpaces) {
      currentErrors.name = true;
    } else {
      currentErrors.name = false;
    }

    setErrors(currentErrors);
    if (currentErrors.name) {
      dataName.setAttribute('invalid', true);
    } else {
      dataName.removeAttribute('invalid');
    }
    
  }

  const verify = function() {
    setVerified(true);
  }


  const passValidations = function() {
    const validations = Object.values(errors);
    // console.log('validations: ', validations);
    for (let valid of validations) {
      if (!valid) return false;
    }

    return true;
  }

  return (
    <>
      {staticForm()}
    </>
  )
}

export default ContactForm;
  