/*

=========================================================
* Now UI Kit React - v1.5.1
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2022 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/main/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import LoginPage from "views/examples/LoginPage.js";
import LandingPage from "views/examples/LandingPage.js";
import ProfilePage from "views/examples/ProfilePage.js";

// pages for Mike's Coring and Cutting
import Entry from "views/Entry.js";
import EntryLandingPage from "views/EntryLandingPage";
const root = ReactDOM.createRoot(document.getElementById("root"));


var oldRoutes = function() {
  return (
    <BrowserRouter>
    <Switch>
      <Switch>
        <Route path="/entry" render={((props) => <Entry {...props} />)} />
        <Route path="/about" render={((props) => <EntryLandingPage {...props} />)}  />
        <Route path="/index" render={(props) => <Index {...props} />} /> 
        {/* <Route
          path="/nucleo-icons"
          render={(props) => <NucleoIcons {...props} />}
        />
        <Route
          path="/landing-page"
          render={(props) => <LandingPage {...props} />}
        />
        <Route
          path="/profile-page"
          render={(props) => <ProfilePage {...props} />}
        />
        <Route
          path="/login-page"
          render={(props) => <LoginPage {...props} />}
        /> */}
        <Redirect to="/entry" />
        <Redirect from="/" to="/entry" />
        {/* <Redirect to="/index" />
        <Redirect from="/" to="/index" /> */}
      </Switch>
    </Switch>
  </BrowserRouter>
  )
}


root.render(
  // all routes
  // <BrowserRouter>
  //   <Switch>
  //     <Switch>
  //       <Route path="/entry" render={((props) => <Entry {...props} />)} />
  //       <Redirect to="/entry" />
  //       <Redirect from="/" to="/entry" />
  //     </Switch>
  //   </Switch>
  // </BrowserRouter>
  oldRoutes()
);
