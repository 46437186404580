const backend = 'http://localhost:3001';
const route = '/api/contact';


const validateSendData = function(data) {
    /* name, email, phonenumber, message 
        minimimum name email or phonenumber and message
    */
    return ((data.name !== '') || (data.email !== '' || data.phonenumber !== '') || (data.message !== ''));
}

const sendMail = function(data) {
    console.log('sendMail data: ', data);
    let valid = validateSendData(data);
    console.log("valid: ", valid);
    if (valid === false) {
        return new Promise((resolve, reject) => reject("Please fill out at least your email or phone number so we can contact you."));
    }

	return new Promise(function(resolve, reject) {
		fetch(backend+route, {
	      method: 'POST',
	      headers: {
	        'Content-Type': 'application/json'
	      },
	      body: JSON.stringify(data),
	    })
	    .then((response) => response.json())
	    .then((data) => {
	      console.log("Success: ", data);
          return resolve(data)
	    })
	    .catch((err) => {
	      console.log("Error: ", err);
          return reject(err);
	    })
	});
}

export default sendMail;