import React from 'react';
// components
import EntryNavbar from 'components/Navbars/EntryNavbar';
import MTCoreHeader from 'components/Headers/MTCoreHeader';

import { Container } from 'reactstrap';
import About from './index-sections/About';
import CarouImages from './index-sections/CarouImages';
import DarkFooter from 'components/Footers/DarkFooter';
import DefaultFooter from 'components/Footers/DefaultFooter';
import ContactForm from './index-sections/ContactForm';
import WhatIsPage from './index-sections/WhatIsPage';

function Entry() {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
          document.body.classList.remove("index-page");
          document.body.classList.remove("sidebar-collapse");
        };
      });
    return (
      <>
        <EntryNavbar />
        <div className="wrapper">
            <MTCoreHeader />
            <Container>
                <div className='main'>
                    <About />
                    {/*<CarouImages />*/}
                </div>
            </Container>
            {/* <WhatIsPage /> */}
            
            <Container>
                <ContactForm />
            </Container>

            <DarkFooter />
        </div>
      </>
    )
}

export default Entry;
