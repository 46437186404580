import React from 'react';

import { 
  Card, 
  CardBody, 
  CardTitle,
  CardText
} from 'reactstrap';

import Reaptcha from 'reaptcha';
import './CardInfo.css';

export function CardInfo(props) {
    return (
        <>
            <Card>
                <CardBody>
                <CardTitle tag="h4">{props.title != undefined ? props.title : "Coring"}</CardTitle>
                  <ul>
                  {props.items.length > 0 && props.items.map((item, index) =>   
                    <li key={index}>{item}</li>
                  )}
                  </ul>
                </CardBody>    
                
            </Card>
        </>
    )
}

export function CardInfoTwo(props) {
    const {title} = props;

    return (
        <div>
            <Card>
                <CardBody>
                    <CardTitle tag="h4">{props.title != undefined ? props.title : "Coring"}</CardTitle>
                    <ul>
                    {
                        props.items && props.items.map((item, index) => 
                            <li key={index}>{item}</li>
                        )
                    }
                    </ul>
                  {/*  <div className={"card-content"}>
                        <p>We can wall saw windows, doorways, walls, HVAC ventilation.</p>
                        <p>Wall sawing can be done in commerical or residential applications, what ever your need is.</p>
                        <p>We can also flush cut walls and floors.</p>
                        <p>We have portable hydraulic tools so we can service your needs on site or on the road anywhere you need cutting done.</p>
                    </div>*/}
{/*                    <div className={"card-content"}>
                        <p>Our slab sawing can cut conrete slabs, curbs expansion and control joints as well as flush cutting for curbs, floors and walls.</p>
                        <p>We also cut asphalt small or big jobs!</p>
                    </div>*/}
                </CardBody>
            </Card>
        </div>
    )
}
// export default CardInfo;
