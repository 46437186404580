/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, NavLink, NavItem } from "reactstrap";

const EMAIL = "1264188bcltd@gmail.com";
const PHONE = "778-885-3556"

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <nav>
          <ul>
            <li>
              <a
                // href="https://www.creative-tim.com?ref=nukr-dark-footer"
                target="_blank"
              >
                MT Coring
                © {new Date().getFullYear()}
                
              </a>
            </li>
          </ul>
        </nav>
        
        <div className="copyright" id="copyright">
          <nav>

          <ul>
            <li>Have a question?</li><span> </span>
            <NavItem>
              <NavLink href={`tel:${PHONE}`}>
                <i className="now-ui-icons ui-2_chat-round mr-1"></i>
                {PHONE}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href={`mailto:${EMAIL}`} target="_blank">
                <i className="now-ui-icons ui-1_email-85 mr-1"></i>
                {EMAIL}
              </NavLink>
            </NavItem>
          </ul>
          </nav>
        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;



{/*<li>
              <i className="now-ui-icons ui-2_chat-round mr-1"></i>
              <a href="tel">604-445-3901</a>
            </li>
            <li>
              <i className="now-ui-icons ui-1_email-85 mr-1"></i>
              <a href="mailto:1264188bcltd@gmail.com">1264188bcltd@gmail.com </a>
            </li>*/}